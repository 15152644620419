import React, { useCallback, useMemo } from 'react';
import filter from 'lodash/filter';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import some from 'lodash/some';

import {
  GroupedMultiSelectOptionalProps,
  GroupedMultiSelectValueType
} from './GroupedMultiSelectFieldControl.types';
import {
  MultiSelect,
  MultiSelectProps,
  MultiSelectValueType
} from '../../../../MultiSelect';
import { MultiSelectChangeCallbackType } from '../../../../MultiSelect/types';
import flatten from 'lodash/flatten';
import find from 'lodash/find';

interface GroupedMultiSelectFieldControlRequiredProps {
  value?: GroupedMultiSelectValueType;
  onChange?: (updateValue: GroupedMultiSelectValueType) => void;
}

function GroupedMultiSelectFieldControl({
  autoFocus,
  classNamePrefix = null,
  closeMenuOnSelect = true,
  data = [],
  defaultValue = null,
  disabled = false,
  emptyValue = null,
  error,
  errorClassName,
  formatGroupLabel,
  formatOptionLabel,
  i18nLabel = null,
  i18nPlaceholder,
  inputWrapperClassName = null,
  isClearable = false,
  isLoading = false,
  isSearchable = false,
  labelClassName,
  menuIsOpen,
  menuPlacement = 'auto',
  menuPosition,
  multi = false,
  name,
  onBlur,
  onChange,
  onInputChange,
  onMenuClose,
  onMenuOpen,
  onMenuScrollToBottom,
  openMenuOnFocus,
  optionsLoading = false,
  placeholder = null,
  showError,
  value,
  sortable,
  filterConfig
}: MultiSelectProps &
  GroupedMultiSelectOptionalProps &
  GroupedMultiSelectFieldControlRequiredProps) {
  const handleChange = useCallback<MultiSelectChangeCallbackType>(
    (updateValue) => {
      onChange(
        isArray(updateValue) ? map(updateValue, 'value') : updateValue?.value
      );
    },
    [onChange]
  );

  const multiSelectValue = useMemo<MultiSelectValueType>(() => {
    const flatData = flatten(map(data, (item) => item.options));

    return isArray(value)
      ? map(value, (value) => find(flatData, (item) => item.value === value))
      : find(flatData, (item) => item.value === value);
  }, [data, value]);

  return (
    <MultiSelect
      autoFocus={autoFocus}
      classNamePrefix={classNamePrefix}
      closeMenuOnSelect={closeMenuOnSelect}
      data={data}
      defaultValue={defaultValue}
      disabled={disabled}
      emptyValue={emptyValue}
      error={error}
      formatGroupLabel={formatGroupLabel}
      formatOptionLabel={formatOptionLabel}
      i18nLabel={i18nLabel}
      i18nPlaceholder={i18nPlaceholder}
      inputWrapperClassName={inputWrapperClassName}
      isClearable={isClearable}
      isSearchable={isSearchable}
      labelClassName={labelClassName}
      menuIsOpen={menuIsOpen}
      menuPlacement={menuPlacement}
      menuPosition={menuPosition}
      multi={multi}
      onChange={handleChange}
      onInputChange={onInputChange}
      onMenuClose={onMenuClose}
      onMenuOpen={onMenuOpen}
      openMenuOnFocus={openMenuOnFocus}
      optionsLoading={optionsLoading}
      placeholder={placeholder}
      value={multiSelectValue}
      name={name}
      onMenuScrollToBottom={onMenuScrollToBottom}
      isLoading={isLoading}
      onBlur={onBlur}
      errorClassName={errorClassName}
      showError={showError}
      sortable={sortable}
      filterConfig={filterConfig}
    />
  );
}

export default GroupedMultiSelectFieldControl;
